import { Form, Formik } from "formik";
import _ from "lodash";
import { Button, Form as BsForm, Modal, ModalProps } from "react-bootstrap";
import * as Yup from "yup";

import { WorkspaceUser, WorkspaceUserRole } from "../../../api";
import { FormItem } from "../../../common/components/form-item";
import { TabComponentMode } from "../../types";

type ModalWorkspaceUser = Pick<WorkspaceUser, "userId" | "role">;

interface WorkspaceUserModalProps extends ModalProps {
  onSubmit: (values: ModalWorkspaceUser) => void;
  isSubmitting: boolean;
  mode?: TabComponentMode;
  workspaceUser?: ModalWorkspaceUser;
  title?: string;
  userIds?: string[];
}

export const WorkspaceUserModal = ({
  onSubmit,
  title,
  show,
  workspaceUser = {
    userId: "",
    role: WorkspaceUserRole.User,
  },
  isSubmitting,
  mode = TabComponentMode.User,
  userIds = [],
  ...modalProps
}: WorkspaceUserModalProps) => {
  const isIncludingUserId = _.isEmpty(workspaceUser.userId);
  const isRoleInputDisabled =
    mode !== TabComponentMode.Admin &&
    workspaceUser.role === WorkspaceUserRole.Owner;
  return (
    <Modal show={show} {...modalProps}>
      <Formik
        validationSchema={Yup.object({
          userId: Yup.string()
            .email()
            .lowercase("User ID needs to be lowercase")
            .required("User ID is required")
            .strict(),
          role: Yup.string().required("Role is required"),
        })}
        onSubmit={onSubmit}
        initialValues={workspaceUser}
      >
        {({ values }) => (
          <Form method="post" noValidate>
            <fieldset disabled={isSubmitting || !show}>
              <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {isIncludingUserId && (
                  <>
                    <FormItem
                      className="mb-3"
                      name="userId"
                      label="User ID"
                      placeholder="email@example.com"
                      type="email"
                      as={BsForm.Control}
                      list="userIdOptions"
                    />
                    <datalist id="userIdOptions">
                      {_.map(
                        _.take(
                          _.sortBy(
                            _.filter(_.map(userIds, _.toLower), (userId) =>
                              _.includes(userId, values.userId)
                            )
                          ),
                          10
                        ),
                        (userId) => (
                          <option value={userId} />
                        )
                      )}
                    </datalist>
                  </>
                )}
                <FormItem
                  className="mb-3"
                  name="role"
                  label="Role"
                  as={BsForm.Select}
                  disabled={isRoleInputDisabled}
                >
                  <option value={WorkspaceUserRole.User}>user</option>
                  <option value={WorkspaceUserRole.Admin}>admin</option>
                  {(mode == TabComponentMode.Admin ||
                    workspaceUser.role === WorkspaceUserRole.Owner) && (
                    <option value={WorkspaceUserRole.Owner}>owner</option>
                  )}
                </FormItem>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" type="submit">
                  Ok
                </Button>
              </Modal.Footer>
            </fieldset>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
