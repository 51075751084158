import { Form, Formik } from "formik";
import _ from "lodash";
import { ReactNode } from "react";
import { Button, Col, Form as BsForm, Row } from "react-bootstrap";
import * as Yup from "yup";

import { Workspace } from "../../../api";
import { FormItem } from "../../../common/components/form-item";
import { PlanId, plans } from "../../../plans";
import { TabComponentMode } from "../../types";

interface WorkspaceFormProps {
  onSubmit: (values: Workspace) => void;
  isSubmitting: boolean;
  mode?: TabComponentMode;
  workspace?: Workspace;
  children: ({
    fields,
    submit,
  }: {
    fields: ReactNode;
    submit: ReactNode;
  }) => ReactNode;
  readOnly?: boolean;
  submitButtonText?: string;
}

const subscriptionPlan = {
  value: PlanId.Subscription,
  label: plans[PlanId.Subscription].label,
};

const csiroInternalPlan = {
  value: PlanId.CsiroInternal,
  label: plans[PlanId.CsiroInternal].label,
};

const planOptions = [csiroInternalPlan, subscriptionPlan];

const settingsByMode = {
  [TabComponentMode.User]: {
    includeCode: false,
  },
  [TabComponentMode.Admin]: {
    includeCode: true,
  },
};

export const WorkspaceForm = ({
  onSubmit,
  workspace = {
    workspaceId: "-",
    planId: PlanId.Subscription,
    label: "",
    code: "",
    tenancyName: "",
    status: "Enabled",
    numberOfSeatsIncluded: plans[PlanId.Subscription].numberOfSeatsIncluded,
  },
  isSubmitting,
  mode = TabComponentMode.User,
  children,
  readOnly = false,
  submitButtonText = "Ok",
}: WorkspaceFormProps) => {
  const settings = settingsByMode[mode];
  const validationObject: Yup.AnyObject = {
    workspaceId: Yup.string().required(),
    label: Yup.string().required("Label is required"),
    planId: Yup.string()
      .oneOf(_.map(planOptions, "value"))
      .required("Plan is required"),
    startDate: Yup.string(),
    endDate: Yup.string(),
    costcode: Yup.string(),
    o2d: Yup.string(),
    wbs: Yup.string(),
  };
  if (settings.includeCode) {
    validationObject.code = Yup.string().required("Code is required");
  }
  const workspaceSchema = Yup.object(validationObject);
  const initialValues = {
    ...workspace,
    startDate: workspace.startDate || "",
    endDate: workspace.endDate || "",
    costcode: workspace.costcode || "",
    o2d: workspace.o2d || "",
    wbs: workspace.wbs || "",
  };
  return (
    <Formik
      validationSchema={workspaceSchema}
      onSubmit={(values) => {
        onSubmit({
          ...values,
          startDate: values.startDate || undefined,
          endDate: values.endDate || undefined,
          costcode: _.isNumber(values.costcode) ? values.costcode : undefined,
          o2d: values.o2d || undefined,
          wbs: values.wbs || undefined,
        });
      }}
      initialValues={initialValues}
    >
      {({ values }) => (
        <Form method="post" noValidate>
          <fieldset disabled={isSubmitting}>
            {children({
              fields: (
                <Row>
                  <Col lg="4">
                    <FormItem
                      className="mb-3"
                      name="workspaceId"
                      label="ID"
                      type="text"
                      placeholder="ID"
                      as={BsForm.Control}
                      readOnly
                    />
                  </Col>
                  <Col lg="4">
                    <FormItem
                      className="mb-3"
                      name="tenancyName"
                      label="Tenancy name"
                      type="text"
                      placeholder="tenancy-name"
                      as={BsForm.Control}
                      readOnly={readOnly || !_.isEmpty(workspace.tenancyName)}
                    />
                  </Col>
                  {settings.includeCode && (
                    <Col lg="4">
                      <FormItem
                        className="mb-3"
                        name="code"
                        label="Code"
                        placeholder="Code"
                        type="text"
                        as={BsForm.Control}
                        readOnly={readOnly}
                      />
                    </Col>
                  )}
                  <Col lg="4">
                    <FormItem
                      className="mb-3"
                      name="planId"
                      label="Plan"
                      as={BsForm.Select}
                      readOnly={readOnly}
                    >
                      {_.isEmpty(workspace.tenancyName) ? (
                        _.map(planOptions, (option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))
                      ) : (
                        <option value={workspace.planId}>
                          {_.get(
                            _.find(planOptions, { value: workspace.planId }),
                            "label"
                          )}
                        </option>
                      )}
                    </FormItem>
                  </Col>
                  <Col lg="12">
                    <FormItem
                      className="mb-3"
                      name="label"
                      label="Label"
                      placeholder="Label"
                      type="text"
                      as={BsForm.Control}
                      readOnly={readOnly}
                    />
                  </Col>
                  <Col lg="4">
                    <FormItem
                      className="mb-3"
                      name="costcode"
                      label="Costcode"
                      type="number"
                      as={BsForm.Control}
                      readOnly={readOnly}
                    />
                  </Col>
                  <Col lg="4">
                    <FormItem
                      className="mb-3"
                      name="o2d"
                      label="O2D"
                      type="text"
                      as={BsForm.Control}
                      readOnly={readOnly}
                    />
                  </Col>
                  <Col lg="4">
                    <FormItem
                      className="mb-3"
                      name="wbs"
                      label="WBS"
                      type="text"
                      as={BsForm.Control}
                      readOnly={readOnly}
                    />
                  </Col>
                  <Col lg="4">
                    <FormItem
                      className="mb-3"
                      name="startDate"
                      label="Start date"
                      type="date"
                      as={BsForm.Control}
                      readOnly={readOnly}
                    />
                  </Col>
                  <Col lg="4">
                    <FormItem
                      className="mb-3"
                      name="endDate"
                      label="End date"
                      type="date"
                      as={BsForm.Control}
                      readOnly={readOnly}
                    />
                  </Col>
                </Row>
              ),
              submit: readOnly ? null : (
                <Button
                  variant="primary"
                  type="submit"
                  disabled={_.isEqual(values, initialValues)}
                >
                  {submitButtonText}
                </Button>
              ),
            })}
          </fieldset>
        </Form>
      )}
    </Formik>
  );
};
