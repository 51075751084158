interface Plan {
  id: string;
  label: string;
  numberOfSeatsIncluded: number | false;
}

export enum PlanId {
  CsiroInternal = "csiro_internal",
  Subscription = "subscription",
}

export const plans: { [key in PlanId]: Plan } = {
  [PlanId.CsiroInternal]: {
    id: PlanId.CsiroInternal,
    label: "CSIRO Internal",
    numberOfSeatsIncluded: false,
  },
  [PlanId.Subscription]: {
    id: PlanId.Subscription,
    label: "Subscription",
    numberOfSeatsIncluded: 5,
  },
};
