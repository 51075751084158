import { Form, Formik } from "formik";
import _ from "lodash";
import {
  Button,
  Col,
  Form as BsForm,
  Modal,
  ModalProps,
  Row,
} from "react-bootstrap";
import * as Yup from "yup";

import { ApproveWorkspaceOwnerFields } from "../../../api";
import { FormItem } from "../../../common/components/form-item";
import { CONFIG } from "../../../config";
import { PlanId, plans } from "../../../plans";

export interface ApproveWorkspaceOwnerModalProps extends ModalProps {
  onSubmit: (values: ApproveWorkspaceOwnerFields) => void;
  isSubmitting: boolean;
}

let schema = Yup.object({
  userId: Yup.string()
    .email()
    .lowercase("User ID needs to be lowercase")
    .required("User ID is required")
    .strict(),
  tenancyName: Yup.string()
    .matches(
      /^[a-z0-9]([-a-z0-9]*[a-z0-9])?$/,
      'Tenancy name needs to match regex "[a-z0-9]([-a-z0-9]*[a-z0-9])?" (lowercase alphanumeric)'
    )
    .required(),
  planId: Yup.string().oneOf(_.keys(plans)).required(),
});

if (CONFIG.IS_BILLING_ENABLED) {
  schema = schema.shape({
    customer: Yup.object({
      email: Yup.string()
        .email()
        .lowercase("User ID needs to be lowercase")
        .required("User ID is required")
        .strict(),
      name: Yup.string().required(),
      address: Yup.string().required(),
      id: Yup.string().required(),
    }),
  });
}
const initialValues: ApproveWorkspaceOwnerFields = {
  userId: "",
  tenancyName: "",
  planId: _.first(_.keys(plans)) as PlanId,
};

if (CONFIG.IS_BILLING_ENABLED) {
  initialValues.customer = {
    id: "",
    address: "",
    email: "",
    name: "",
  };
}

export const ApproveWorkspaceOwnerModal = ({
  onSubmit,
  show,
  isSubmitting,
  ...modalProps
}: ApproveWorkspaceOwnerModalProps) => {
  return (
    <Modal show={show} {...modalProps} size="lg">
      <Formik
        validationSchema={schema}
        onSubmit={onSubmit}
        initialValues={initialValues}
      >
        <Form method="post" noValidate>
          <fieldset disabled={isSubmitting || !show}>
            <Modal.Header closeButton>
              <Modal.Title>Approve workspace owner</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col xs="6">
                  <FormItem
                    className="mb-3"
                    name="userId"
                    label="User ID"
                    type="email"
                    placeholder="email"
                    as={BsForm.Control}
                  />
                </Col>
                <Col xs="6">
                  <FormItem
                    className="mb-3"
                    name="tenancyName"
                    label="Tenancy Name"
                    type="text"
                    placeholder="Tenancy name"
                    as={BsForm.Control}
                  />
                </Col>
                <Col xs="6">
                  <FormItem
                    className="mb-3"
                    name="planId"
                    label="Plan"
                    as={BsForm.Select}
                  >
                    {_.map(_.values(plans), (option) => (
                      <option key={option.id} value={option.id}>
                        {option.label}
                      </option>
                    ))}
                  </FormItem>
                </Col>
              </Row>
              {CONFIG.IS_BILLING_ENABLED && (
                <Row>
                  <Col xs="6">
                    <FormItem
                      className="mb-3"
                      name="customer.id"
                      label="Customer ID"
                      placeholder="SAP customer ID"
                      as={BsForm.Control}
                    />
                  </Col>
                  <Col xs="6">
                    <FormItem
                      className="mb-3"
                      name="customer.name"
                      label="Customer name"
                      placeholder="Enter name"
                      as={BsForm.Control}
                    />
                  </Col>
                  <Col xs="6">
                    <FormItem
                      className="mb-3"
                      name="customer.address"
                      label="Customer address"
                      placeholder="address"
                      as={BsForm.Control}
                    />
                  </Col>
                  <Col xs="6">
                    <FormItem
                      className="mb-3"
                      name="customer.email"
                      label="Billing email"
                      type="email"
                      placeholder="email"
                      as={BsForm.Control}
                    />
                  </Col>
                </Row>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" type="submit">
                Ok
              </Button>
            </Modal.Footer>
          </fieldset>
        </Form>
      </Formik>
    </Modal>
  );
};
