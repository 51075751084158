import _ from "lodash";
import { useEffect, useState } from "react";
import { Alert, Button, Modal, Stack } from "react-bootstrap";
import { SubmitFunction } from "react-router-dom";
import { SubmitTarget } from "react-router-dom/dist/dom";

import {
  FileDownloader,
  StatusError,
} from "../../../activity/components/file-downloader";
import { apiUrl, WorkspaceWithUsers } from "../../../api";
import { TabComponentMode } from "../../types";
import { WorkspaceForm } from "../workspace-form";
import { WorkspacesList } from "../workspaces-list";

interface ModalState {
  show: boolean;
  title?: string;
  workspace?: WorkspaceWithUsers;
  method?: "post" | "patch";
}

interface WorkspacesProps {
  workspaces: WorkspaceWithUsers[];
  isSubmitting: boolean;
  onSubmit: SubmitFunction;
  authedUserId: string;
  mode?: TabComponentMode;
}

const settingsByMode = {
  [TabComponentMode.User]: {
    hasCsvButtons: false,
    hasAddButton: false,
  },
  [TabComponentMode.Admin]: {
    hasCsvButtons: true,
    hasAddButton: true,
  },
};

export const Workspaces = ({
  onSubmit,
  isSubmitting,
  workspaces,
  authedUserId,
  mode = TabComponentMode.User,
}: WorkspacesProps) => {
  const [modal, setModal] = useState<ModalState>({ show: false });
  useEffect(() => {
    if (!isSubmitting) {
      setModal({ show: false });
    }
  }, [isSubmitting]);
  const settings = settingsByMode[mode];
  return (
    <>
      <div className="d-flex justify-content-start mb-5">
        <Stack direction="horizontal" gap={3}>
          {settings.hasAddButton && (
            <Button
              variant="primary"
              onClick={() =>
                setModal({ show: true, title: "Add workspace", method: "post" })
              }
            >
              Add workspace
            </Button>
          )}
          {settings.hasCsvButtons && (
            <>
              <input
                type="file"
                id="import-csv-file"
                className="visually-hidden"
                onChange={(e) => {
                  if (_.isNull(e.target.files)) {
                    return;
                  }
                  const formData = new FormData();
                  formData.append("file", e.target.files[0]);
                  onSubmit(formData, {
                    method: "post",
                    encType: "multipart/form-data",
                  });
                }}
              />
              <Button
                as="label"
                role=""
                htmlFor="import-csv-file"
                variant="primary"
              >
                Import CSV
              </Button>
              <FileDownloader
                requestConfig={{ url: apiUrl("workspaces/csv") }}
                name="workspaces.csv"
              >
                {({ onClick, isLoading, error }) => {
                  if (error instanceof StatusError && error.status === 403) {
                    window.location.reload();
                  } else if (error) {
                    console.error(error);
                  }
                  return (
                    <Button
                      variant={_.isUndefined(error) ? "primary" : "danger"}
                      onClick={onClick}
                      disabled={isLoading}
                    >
                      {_.isUndefined(error)
                        ? "Export CSV"
                        : "Failed. Try again!"}
                    </Button>
                  );
                }}
              </FileDownloader>
            </>
          )}
        </Stack>
      </div>
      {workspaces.length === 0 ? (
        <Alert variant="info">No workspaces</Alert>
      ) : (
        <WorkspacesList
          authedUserId={authedUserId}
          isSubmitting={isSubmitting}
          workspaces={workspaces}
          mode={mode}
        />
      )}
      <Modal
        show={modal.show}
        size="xl"
        onHide={() => setModal({ show: false })}
      >
        <Modal.Header closeButton>
          <Modal.Title>{modal.title}</Modal.Title>
        </Modal.Header>
        <WorkspaceForm
          isSubmitting={isSubmitting}
          onSubmit={(values) => {
            if (_.isEqual(values, modal.workspace)) {
              setModal({ show: false });
            } else {
              onSubmit(values as unknown as SubmitTarget, {
                method: modal.method,
                encType: "application/json",
              });
            }
          }}
          workspace={modal.workspace}
          mode={mode}
        >
          {({ fields, submit }) => (
            <>
              <Modal.Body>{fields}</Modal.Body>
              <Modal.Footer>{submit}</Modal.Footer>
            </>
          )}
        </WorkspaceForm>
      </Modal>
    </>
  );
};
