import _ from "lodash";
import { useEffect, useState } from "react";
import { Alert, Button, Modal, Stack } from "react-bootstrap";
import { SubmitFunction } from "react-router-dom";
import { SubmitTarget } from "react-router-dom/dist/dom";

import {
  FileDownloader,
  StatusError,
} from "../../../activity/components/file-downloader";
import { apiUrl, User } from "../../../api";
import { ApproveWorkspaceOwnerModal } from "../approve-workspace-owner-modal";
import { UserForm } from "../user-form";
import { UsersList } from "../users-list";

interface ModalState {
  show: boolean;
  title?: string;
  user?: User;
  method?: "post" | "patch";
}

interface UsersProps {
  users: User[];
  isSubmitting: boolean;
  onSubmit: SubmitFunction;
}

export const Users = ({ onSubmit, isSubmitting, users }: UsersProps) => {
  const [userModal, setUserModal] = useState<ModalState>({ show: false });
  const [approveModal, setApproveModal] = useState<ModalState>({ show: false });
  useEffect(() => {
    if (!isSubmitting) {
      setUserModal({ show: false });
      setApproveModal({ show: false });
    }
  }, [isSubmitting]);
  if (users.length === 0) {
    return <Alert variant="info">No users</Alert>;
  }
  return (
    <>
      <div className="d-flex flex-wrap justify-content-start mb-3">
        <Stack className="mb-3 flex-wrap" direction="horizontal" gap={3}>
          <Button
            variant="primary"
            onClick={() => setApproveModal({ show: true })}
          >
            Approve workspace owner
          </Button>
          <Button
            variant="primary"
            onClick={() =>
              setUserModal({ show: true, title: "Add user", method: "post" })
            }
          >
            Add user
          </Button>
          <input
            type="file"
            id="import-csv-file"
            className="visually-hidden"
            onChange={(e) => {
              if (_.isNull(e.target.files)) {
                return;
              }
              const formData = new FormData();
              formData.append("file", e.target.files[0]);
              onSubmit(formData, {
                method: "post",
                encType: "multipart/form-data",
              });
            }}
          />
          <Button
            as="label"
            role=""
            htmlFor="import-csv-file"
            variant="primary"
          >
            Import CSV
          </Button>
          <FileDownloader
            requestConfig={{ url: apiUrl("users/csv") }}
            name="users.csv"
          >
            {({ onClick, isLoading, error }) => {
              if (error instanceof StatusError && error.status === 403) {
                window.location.reload();
              } else if (error) {
                console.error(error);
              }
              return (
                <Button
                  variant={_.isUndefined(error) ? "primary" : "danger"}
                  onClick={onClick}
                  disabled={isLoading}
                >
                  {_.isUndefined(error) ? "Export CSV" : "Failed. Try again!"}
                </Button>
              );
            }}
          </FileDownloader>
        </Stack>
      </div>
      <UsersList users={users} />
      <UserForm
        isSubmitting={isSubmitting}
        onSubmit={(values) => {
          if (_.isEqual(values, userModal.user)) {
            setUserModal({ show: false });
          } else {
            onSubmit(_.omit(values, "enabled") as unknown as SubmitTarget, {
              method: userModal.method,
              encType: "application/json",
            });
          }
        }}
        user={userModal.user}
      >
        {({ fields, submit }) => (
          <Modal
            size="xl"
            show={userModal.show}
            onHide={() => setUserModal({ show: false })}
          >
            <Modal.Header closeButton>
              <Modal.Title>{userModal.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{fields}</Modal.Body>
            <Modal.Footer>{submit}</Modal.Footer>
          </Modal>
        )}
      </UserForm>
      <ApproveWorkspaceOwnerModal
        isSubmitting={isSubmitting}
        onHide={() => setApproveModal({ show: false })}
        show={approveModal.show}
        onSubmit={(values) =>
          onSubmit(
            {
              action: "approve-workspace-owner",
              ...values,
            } as unknown as SubmitTarget,
            {
              method: "post",
              encType: "application/json",
            }
          )
        }
      />
    </>
  );
};
