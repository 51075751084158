import _ from "lodash";
import * as Yup from "yup";

import { UserProjectValue } from "../../../api";

export const userFieldsSchema = Yup.object({
  userId: Yup.string()
    .email()
    .lowercase("User ID needs to be lowercase")
    .required("User ID is required")
    .strict(),
  groups: Yup.array(Yup.string().required("Group is required")),
  projects: Yup.array(
    Yup.object().shape({
      key: Yup.string().required("Project key is required"),
      value: Yup.string()
        .oneOf(
          _.values(UserProjectValue),
          "Project value must be one of the following values: rw, ro, true"
        )
        .required("Project value is required"),
    })
  ),
  customer: Yup.object({
    email: Yup.string()
      .email()
      .lowercase("User ID needs to be lowercase")
      .strict(),
    name: Yup.string(),
    address: Yup.string(),
    id: Yup.string(),
  }).test({
    name: "is-all-or-nothing",
    test: (value, testContext) => {
      const keys = ["id", "name", "address", "email"];
      const expected = _.isEmpty(_.get(value, _.first(keys) as string));
      for (const key of _.tail(keys)) {
        if (expected !== _.isEmpty(_.get(value, key))) {
          return testContext.createError({
            path: `customer.${key}`,
            message: "All or no customer fields must be set",
          });
        }
      }
      return true;
    },
  }),
});
