import React from "react";
import { Await, useLoaderData } from "react-router-dom";

import { WorkspaceWithUsers } from "../../../api";
import { Spinner } from "../../../common/components/spinner";
import { TabComponentMode } from "../../types";
import { useWorkspaces } from "./use-workspaces";
import { Workspaces } from "./workspaces.component";

interface ConnectedWorkspacesProps {
  mode: TabComponentMode;
}

export interface WorskpaceLoader {
  workspaces: Promise<WorkspaceWithUsers[]>;
}

export const ConnectedWorkspaces = ({ mode }: ConnectedWorkspacesProps) => {
  const data = useLoaderData() as WorskpaceLoader;
  const { isSubmitting, onSubmit, authedUserId } = useWorkspaces();
  return (
    <React.Suspense fallback={<Spinner />}>
      <Await resolve={data.workspaces}>
        {(workspaces: WorkspaceWithUsers[]) => (
          <Workspaces
            authedUserId={authedUserId}
            workspaces={workspaces}
            onSubmit={onSubmit}
            isSubmitting={isSubmitting}
            mode={mode}
          />
        )}
      </Await>
    </React.Suspense>
  );
};
