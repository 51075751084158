import _ from "lodash";

import { fetcher } from "../auth";
import { CONFIG } from "../config";
import { PlanId } from "../plans";
import { apiUrl } from "./utils";
import { Workspace } from "./workspaces";

export enum WorkspaceUserRole {
  Admin = "admin",
  User = "user",
  Owner = "owner",
}

export enum UserProjectValue {
  ReadOnly = "ro",
  ReadWrite = "rw",
  True = "true",
}

export interface UserProject {
  key: string;
  value: UserProjectValue;
}

export type UserGroup = string;

export interface AuthProviderUser {
  username: string;
  name: string;
  email: string;
  status:
    | "UNCONFIRMED"
    | "CONFIRMED"
    | "EXTERNAL_PROVIDER"
    | "UNKNOWN"
    | "RESET_REQUIRED"
    | "FORCE_CHANGE_PASSWORD";
  enabled: boolean | undefined;
  created: string;
  modified: string;
}

export interface Customer {
  email: string;
  id: string;
  name: string;
  address: string;
}

export interface UsersWorkspace {
  workspace: Workspace;
  role: WorkspaceUserRole;
}

export interface User {
  userId: string;
  username: string;
  name: string;
  groups: UserGroup[];
  enabled: boolean | undefined;
  projects: UserProject[];
  customer?: Customer | null;
}

export interface UserWithWorkspaces extends User {
  workspaces: UsersWorkspace[];
}

export interface UsersResponseItem {
  user_id: string;
  groups: string[];
  projects: { [key: string]: UserProjectValue };
  provided?: AuthProviderUser;
  customer?: Customer;
}

export interface ApiUserFields {
  user_id?: string;
  groups?: string[];
  projects?: { [key: string]: UserProjectValue };
  enabled?: boolean;
  customer?: Customer | null;
}

interface RequestAllArguments {
  signal: AbortSignal;
}
interface RequestArguments extends RequestAllArguments {
  userId: string;
}

export interface ApproveWorkspaceOwnerFields {
  userId: string;
  tenancyName: string;
  planId: PlanId;
  customer?: Customer;
}

export const convertToApiUserFields = (user: Partial<User>): ApiUserFields => {
  const fields: ApiUserFields = {
    groups: _.get(user, "groups"),
    projects: _.isUndefined(user.projects)
      ? undefined
      : _.zipObject(_.map(user.projects, "key"), _.map(user.projects, "value")),
    enabled: _.get(user, "enabled"),
  };
  if (CONFIG.IS_BILLING_ENABLED) {
    fields.customer = _.get(user, "customer");
  }
  return fields;
};

export const Users = {
  parseFromApi(fetched: UsersResponseItem[]): User[] {
    return _.map(fetched, (user) => ({
      userId: user.user_id,
      name: _.get(user, "provided.name", ""),
      username: _.get(user, "provided.username", ""),
      enabled: _.get(user, "provided.enabled"),
      groups: user.groups,
      projects: _.map(user.projects, (value, key) => ({ key, value })),
      customer: _.isObject(user.customer) ? user.customer : undefined,
    }));
  },
  async fetch({ signal }: RequestAllArguments): Promise<UsersResponseItem[]> {
    const { data } = await fetcher({ url: apiUrl("users/all"), signal });
    return data as UsersResponseItem[];
  },
  async fetchAllAndParse(args: RequestAllArguments): Promise<User[]> {
    const data = await Users.fetch(args);
    return Users.parseFromApi(data);
  },
  async fetchAndParse(args: RequestArguments): Promise<User> {
    // @TODO: Optimise by only fetching the one user.
    const users = await Users.fetchAllAndParse(args);
    const user = _.find(users, { userId: args.userId });
    if (_.isUndefined(user)) {
      throw new Error("User is undefined");
    }
    return user;
  },
  approve(values: ApproveWorkspaceOwnerFields) {
    return fetcher({
      method: "post",
      url: apiUrl(
        `approve/workspace-owner/${encodeURIComponent(values.userId)}`
      ),
      data: {
        tenancy_name: values.tenancyName,
        plan_id: values.planId,
        customer: values.customer,
      },
    });
  },
  create(values: User) {
    return fetcher({
      url: apiUrl(`users/${encodeURIComponent(values.userId)}`),
      method: "POST",
      data: convertToApiUserFields(values),
    });
  },
  delete(values: User) {
    return fetcher({
      url: apiUrl(`users/${encodeURIComponent(values.userId)}`),
      method: "DELETE",
    });
  },
  update(values: User) {
    return fetcher({
      url: apiUrl(`users/${encodeURIComponent(values.userId)}`),
      method: "PATCH",
      data: convertToApiUserFields(values),
    });
  },
  async importCsv(request: Request) {
    return fetcher({
      url: apiUrl("users/csv"),
      method: request.method,
      data: await request.formData(),
      headers: {
        "Content-Type": request.headers.get("Content-Type"),
      },
    });
  },
};
